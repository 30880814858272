import React from 'react'
import { Seo } from "../components/seo/default"
import { graphql } from "gatsby"
import { Layout } from "../components/layout/layout"

export default function ArticlePage({
    data: { page }
}) {
    return (
        <Layout>
            <Seo title={`${page.title}`} />
            <section className='mx-auto max-w-screen-xl'>
                <div className="page-content px-4 py-2">
                    <h3 className="text-sm font-semibold py-2">{page.title}</h3>
                    <div className={`text-sm py-3 page-description`} dangerouslySetInnerHTML={{__html: page.body}}></div>
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
  query($id: String!) {
    page: shopifyPage(id: { eq: $id }) {
        id
        title
        handle
        bodySummary
        body
        createdAt
        onlineStoreUrl
    }
  }
`